import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {SiteMetadata} from "../types/site-metadata.type";
import Hero from "../components/templates/hero/Hero";
import FeaturesZPattern from "../components/templates/features-z-pattern/FeaturesZPattern";
import SpecialOffer from "../components/templates/special-offer/SpecialOffer";
import Help from "../components/templates/help/Help";
import Layout from "../components/Layout";
import {IHeroSection} from "../components/templates/hero/hero-section.interface";
import {ISpecialOfferSection} from "../components/templates/special-offer/special-offer.interface";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import {IFeatureSection} from "../components/templates/features-z-pattern/feature-section.interface";
import Features from "../components/homepage/features/Features";
import {PageContextType} from "../types/page-context.type";
import StructuredData from '../assets/structured-data';

type ApplicationsProps = {
  strapiUsage: {
    hero: IHeroSection,
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
    properties: IFeatureSection[],
    hiddenProperties: IFeatureSection[],
    siteMetadata: SiteMetadata,
    green?: boolean,
  }
} & SiteMetadataConfigProps

const Applications = ({data, pageContext}: PageProps<ApplicationsProps, PageContextType>) => {
  const {hero, siteMetadata, specialOffer, properties, hiddenProperties, help, green} = data.strapiUsage;
  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <Hero green={green} title={hero.title} image={hero.image}/>
      <FeaturesZPattern features={properties} hiddenFeatures={hiddenProperties} id={'features-section'} green={green}/>
      <Features />
      <SpecialOffer
        green={green}
        className={'bg-white md:pt-20 md:pb-36 md:px-10 relative z-0 items-center flex justify-center mt-20'}
        specialOffer={specialOffer}
      />
      <Help helpSection={help} />
    </Layout>
  )
}

export default Applications;

export const Head = (props: HeadProps<ApplicationsProps>) => {
  const slugs = props.location.pathname.split('/');
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiUsage.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: 'Monitoring pojazdów',
          item: siteUrl + '/' + slugs[1] + '/'
        },
        {
          name: crumbLabel,
          item: siteUrl + '/' + slugs[1] + '/' + slugs[2] + '/'
        },
      ])}
    </>
  )
}

export const pageQuery = graphql`
  query ApplicationsPage($slug: String) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiUsage(slug: {eq: $slug}) {
      green
      siteMetadata {
        crumbLabel
        title
        description
      }
      hero {
        title
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      properties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        button {
          label
          url
        }
      }
      hiddenProperties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        button {
          label
          url
        }
      }
      help {
        title
        number
        email
        description
      }
      specialOffer {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
